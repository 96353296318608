import React, { useState, useEffect } from "react";
import { Tooltip } from "@fluentui/react-components";
import { FaDownload, FaRegEdit, FaTrash, FaPlay } from "react-icons/fa";
import { IoPlayOutline, IoCloudDownloadOutline } from "react-icons/io5";
import { BsTrash } from "react-icons/bs";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./RequestTable.css";
import { sidebarTranslations } from "./Sidebar";
import EditModal from './EditModal';

type Language = 'en' | 'es' | 'fr' | 'pt' | 'de' | 'hi';

interface RequestTableProps {
  data: any[];
  language: Language;
}

const RequestTable: React.FC<RequestTableProps> = ({ data, language }) => {
  const [sortConfig, setSortConfig] = useState<{ key: string; direction: string }>({
    key: "requestTime",
    direction: "descending",
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [tableData, setTableData] = useState<any[]>(data);

  useEffect(() => {
    setTableData(data);
  }, [data]);

  const sasToken = "sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-11-14T05:08:44Z&st=2024-11-13T21:08:44Z&spr=https&sig=mAhLnyOIKLS7Kf3qqy7llpE0c1R32l1CQeWc8ABMd0M%3D";

  const t = sidebarTranslations[language as keyof typeof sidebarTranslations];

  const sortedData = React.useMemo(() => {
    if (sortConfig !== null) {
      return [...tableData].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return tableData;
  }, [tableData, sortConfig]);

  const requestSort = (key: string) => {
    let direction = "ascending";
    if (sortConfig && sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const getSortIndicator = (key: string) => {
    if (!sortConfig || sortConfig.key !== key) {
      return "";
    }
    return sortConfig.direction === "ascending" ? "▲" : "▼";
  };

  const handleDownload = (url: string) => {
    if (url) {
      const downloadUrl = `${url}?${sasToken}`;
      window.open(downloadUrl, "_blank");
    } else {
      console.error("No URL available for download");
    }
  };

  const handleDeleteClick = (item: any) => {
    setSelectedItem(item);
    setIsModalOpen(true);
  };

  const confirmDelete = async () => {
    if (selectedItem) {
      try {
        const response = await fetch(`https://acvappapi-preprod.acv.app/api/avatar-request/${selectedItem.requestId}`, {
          method: 'DELETE',
        });

        if (response.ok) {
          toast.success("Registro borrado exitosamente");
          const updatedData = tableData.filter(item => item.requestId !== selectedItem.requestId);
          setTableData(updatedData);
        } else {
          console.error("Error al eliminar el item");
        }
      } catch (error) {
        console.error("Error en la solicitud:", error);
      } finally {
        setIsModalOpen(false);
        setSelectedItem(null);
      }
    }
  };

  const cancelDelete = () => {
    setIsModalOpen(false);
    setSelectedItem(null);
  };

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const handleEditClick = (item: any) => {
    setSelectedItem(item);
    setIsEditModalOpen(true);
  };

  const handleSave = async (updatedItem: any) => {
    console.log("Valores a actualizar:", updatedItem);

    try {
      const response = await fetch(`https://acvappapi-preprod.acv.app/api/avatar-request/update-by-id`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: updatedItem.requestId,
          fields: {
            userPrompt: updatedItem.userPrompt,
            selectedLanguage: updatedItem.selectedLanguage,
            selectedGender: updatedItem.selectedGender,
            requestTime: updatedItem.requestTime,
            avatarImage: updatedItem.avatarImage,
            backgroundImage: updatedItem.backgroundImage,
            status: updatedItem.status,
            videoUrl: updatedItem.videoUrl,
          },
        }),
      });

      if (response.ok) {
        const updatedData = tableData.map(item => item.requestId === updatedItem.requestId ? updatedItem : item);
        setTableData(updatedData);
        toast.success("Registro actualizado exitosamente");
      } else {
        console.error("Error al actualizar el registro");
      }
    } catch (error) {
      console.error("Error en la solicitud:", error);
    } finally {
      setIsEditModalOpen(false);
    }
  };

  return (
    <div className="request-table">
      <table>
        <thead>
          <tr>
            <th className="fecha" onClick={() => requestSort("requestTime")}>
              {t.date} {getSortIndicator("requestTime")}
            </th>
            <th className="avatar" onClick={() => requestSort("avatarImage")}>
              {t.avatar} {getSortIndicator("avatarImage")}
            </th>
            <th className="fondo" onClick={() => requestSort("backgroundImage")}>
              {t.background} {getSortIndicator("backgroundImage")}
            </th>
            <th className="prompt" onClick={() => requestSort("userPrompt")}>
              {t.prompt} {getSortIndicator("userPrompt")}
            </th>
            <th className="idioma" onClick={() => requestSort("selectedLanguage")}>
              {t.language} {getSortIndicator("selectedLanguage")}
            </th>
            <th className="género" onClick={() => requestSort("selectedGender")}>
              {t.gender} {getSortIndicator("selectedGender")}
            </th>
            <th className="clase" onClick={() => requestSort("lessonName")}>
              {t.class} {getSortIndicator("lessonName")}
            </th>
            <th className="estado" onClick={() => requestSort("status")}>
              {t.status} {getSortIndicator("status")}
            </th>
            <th className="video">{t.video}</th>
            <th className="acciones">{t.actions}</th>
          </tr>
        </thead>
        <tbody>
          {sortedData.map((item: any, index: number) => (
            <tr key={index}>
              <td>
                <Tooltip content={item.requestTime || "N/A"} relationship="description">
                  <span>
                    {item.requestTime && item.requestTime.length > 12
                      ? `${item.requestTime.substring(0, 20)}...`
                      : item.requestTime || "N/A"}
                  </span>
                </Tooltip>
              </td>
              <td>
                <img
                  src={`${item.avatarImage || ""}?${sasToken}`}
                  alt="Avatar"
                  style={{ width: "75px", height: "50px" }}
                />
              </td>
              <td>
                <img
                  src={`${item.backgroundImage || ""}?${sasToken}`}
                  alt="Fondo"
                  style={{ width: "75px", height: "50px" }}
                />
              </td>
              <td>
                <Tooltip content={item.userPrompt || "N/A"} relationship="description">
                  <span>
                    {item.userPrompt && item.userPrompt.length > 50
                      ? `${item.userPrompt.substring(0, 50)}...`
                      : item.userPrompt || "N/A"}
                  </span>
                </Tooltip>
              </td>
              <td>{item.selectedLanguage || "N/A"}</td>
              <td>{item.selectedGender || "N/A"}</td>
              <td>
                <Tooltip content={item.lessonName || "N/A"} relationship="description">
                  <span>
                    {item.lessonName && item.lessonName.length > 12
                      ? `${item.lessonName.substring(0, 12)}...`
                      : item.lessonName || "N/A"}
                  </span>
                </Tooltip>
              </td>
              <td>{item.status || "N/A"}</td>
              <td>
                {item.videoUrl ? (
                  <img
                    src="/assets/images/videotransparent.png"
                    alt="Video"
                    style={{ width: "50px", height: "35px" }}
                  />
                ) : (
                  <span>No Video</span>
                )}
              </td>
              <td>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <button onClick={() => handleDownload(item.videoUrl || "")} style={{ marginRight: "10px" }}>
                    <IoCloudDownloadOutline />
                  </button>
                  <button style={{ marginRight: "10px" }} onClick={() => handleEditClick(item)}>
                    <FaRegEdit />
                  </button>
                  <button style={{ marginRight: "10px" }} onClick={() => handleDeleteClick(item)}>
                    <BsTrash />
                  </button>
                  <button>
                    <IoPlayOutline />
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <p>¿Estás seguro de que deseas eliminar este registro?</p>
            <p>
              {selectedItem?.userPrompt.length > 100
                ? `${selectedItem.userPrompt.substring(0, 100)}...`
                : selectedItem?.userPrompt}
            </p>
            <button onClick={confirmDelete}>Confirmar</button>
            <button onClick={cancelDelete}>Cancelar</button>
          </div>
        </div>
      )}
      {isEditModalOpen && selectedItem && (
        <EditModal
          item={selectedItem}
          onClose={() => setIsEditModalOpen(false)}
          onSave={handleSave}
        />
      )}
    </div>
  );
};

export default RequestTable; 