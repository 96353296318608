import { Formik, Form, Field as FField } from "formik";
import React, { useEffect, useState, useRef } from "react";
import { api, getBackgroundImage } from "../../api";
import {
  Image,
  Body1Strong,
  Button,
  Textarea,
  Combobox,
  Option,
} from "@fluentui/react-components";
import COMBOBOX from "../../Component/Combobox";
import TEXT from "../../Component/Text";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import Loader from "../../Component/Loader";
import UPLOADIMAGE from "../../Component/ImageUploader";
import { toast } from "react-toastify";
import DROPDOWNWITHIMAGECONTAINER from "../../Component/AvatarCombo";
import * as Yup from "yup";
import axios from "axios";
import "./index.css";
import "./composer.css";
import RequestTable from "../../Component/RequestTable";
import Sidebar from "../../Component/Sidebar";
import { FaLightbulb, FaList } from 'react-icons/fa';
import { useDarkMode } from '../../context/DarkModeContext';

interface AvatarGenerator2Props {
  slug?: string;
  owner?: string;
  lessonID?: string;
  language?: string;
  host?: string;
  gpu?: string;
  database?: string;
}

interface SelectedLessonType {
  lessonId: string;
  lessonName: string;
}

type Language = 'en' | 'es' | 'fr' | 'pt' | 'de' | 'hi'; // Add all languages

const getBackgroundPreviewUrl = (fileName: string) => {
  return `${api.defaults.baseURL}/getBackgroundPreview/${fileName}`;
};

const BackgroundPreview: React.FC<{ 
  backgroundUrl: string;
  avatarUrl?: string;
  t: any;
  isDarkMode: boolean;
}> = ({ backgroundUrl, avatarUrl, t, isDarkMode }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const shouldLoadImage = avatarUrl && backgroundUrl;

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
      <div style={{
        width: '100%',
        height: '200px',
        border: '1px solid #ddd',
        borderRadius: '0px',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#fff',
        position: 'relative',
        padding: 0,
        margin: 0,
        marginTop: '100px',
      }}>
        {shouldLoadImage ? (
          <>
            {error && <div style={{ color: 'red' }}>{error}</div>}
            <img 
              src={backgroundUrl}
              alt="Background preview"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                display: isLoading ? 'none' : 'block'
              }}
              onLoad={() => setIsLoading(false)}
              onError={() => {
                console.error('Error loading image');
                setError('Error loading image');
                setIsLoading(false);
              }}
            />
            {avatarUrl && (
              <img 
                src={avatarUrl}
                alt="Avatar preview"
                style={{
                  position: 'absolute',
                  bottom: '0px',
                  left: '50%',
                  transform: 'translateX(-50%)',
                  height: '105%',
                  objectFit: 'cover',
                  display: isLoading ? 'none' : 'block'
                }}
              />
            )}
            {isLoading && <div>Loading background preview...</div>}
          </>
        ) : (
          <div style={{ color: isDarkMode ? '#fff' : '#000' }}>
            {!avatarUrl ? t.selectAvatar : t.selectBackground}
          </div>
        )}
      </div>
    </div>
  );
};

const languageNames: Record<Language, string> = {
  en: "English",
  es: "Español",
  fr: "Français",
  pt: "Português",
  de: "Deutsch",
  hi: "हिंदी",
};

const AvatarGenerator2: React.FC<AvatarGenerator2Props> = ({
  slug,
  owner,
  lessonID,
  language: propLanguage,
  host,
  gpu,
  database,
}) => {
  const { isDarkMode, toggleDarkMode } = useDarkMode();

  const [language, setLanguage] = useState<Language>(propLanguage as Language);

  const handleLanguageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedLanguage = event.target.value as Language;
    setLanguage(selectedLanguage);
  };

  const translations = {
    en: {
      title: "Avatar Generator With Animation",
      chooseAvatar: "Choose Your Avatar",
      selectAvatar: "Select Avatar",
      chooseBackground: "Choose Your Background",
      selectBackground: "Select Background",
      videoPreview: "Preview",
      selectBackgroundPreview: "Select a background to preview",
      finalAdjustments: "Final adjustments",
      enterPrompt: "Enter Your Prompt Here",
      enterSpeech: "Enter speech",
      chooseLanguage: "Choose Language",
      selectLanguage: "Select language for voice",
      chooseGender: "Choose Gender Voice",
      selectGender: "Select Gender Voice",
      submitRequest: "Submit Request",
      myRequests: "My Requests",
      previewDescription: "This is a rough preview of how your avatar will look on the selected background. The avatar will automatically adjust to the optimal size and position for the best presentation. You can change both the avatar and the background at any time before submitting your application.",
      submissionSuccess: "Avatar request successfully submitted, once your avatar is generated you will receive an email notification. In case your request is for a lesson in Assignments, it will be automatically linked.",
      promptError: "Please enter a prompt; it cannot be empty.",
      charactersRemaining: "characters remaining",
    },
    es: {
      title: "Generador de Avatares con Animación",
      chooseAvatar: "Elige tu Avatar",
      selectAvatar: "Seleccionar Avatar",
      chooseBackground: "Elige tu Fondo",
      selectBackground: "Seleccionar Fondo",
      videoPreview: "Vista Previa",
      selectBackgroundPreview: "Selecciona un fondo para previsualizar",
      finalAdjustments: "Ajustes finales",
      enterPrompt: "Introduce tu Mensaje Aquí",
      enterSpeech: "Introduce el discurso",
      chooseLanguage: "Elige el Idioma",
      selectLanguage: "Selecciona el idioma para la voz",
      chooseGender: "Elige la Voz de Género",
      selectGender: "Selecciona la Voz de Género",
      submitRequest: "Enviar Solicitud",
      myRequests: "Mis Solicitudes",
      previewDescription: "Esta es una vista previa aproximada de cómo se verá tu avatar en el fondo seleccionado. El avatar se ajustará automáticamente al tamaño y posición óptimos para la mejor presentación. Puedes cambiar tanto el avatar como el fondo en cualquier momento antes de enviar tu solicitud.",
      submissionSuccess: "Solicitud de avatar enviada con éxito, una vez que tu avatar sea generado recibirás una notificación por correo electrónico. En caso de que tu solicitud sea para una lección en Asignaciones, se vinculará automáticamente.",
      promptError: "Por favor, introduce un mensaje; no puede estar vacío.",
      charactersRemaining: "caracteres restantes",
    },
    fr: {
      title: "Générateur d'Avatars avec Animation",
      chooseAvatar: "Choisissez votre Avatar",
      selectAvatar: "Sélectionner un Avatar",
      chooseBackground: "Choisissez votre Fond",
      selectBackground: "Sélectionner un Fond",
      videoPreview: "Aperçu",
      selectBackgroundPreview: "Sélectionnez un fond pour prévisualiser",
      finalAdjustments: "Ajustements finaux pour générer votre avatar",
      enterPrompt: "Entrez votre Message Ici",
      enterSpeech: "Entrez le discours",
      chooseLanguage: "Choisissez la Langue",
      selectLanguage: "Sélectionnez la langue pour la voix",
      chooseGender: "Choisissez la Voix de Genre",
      selectGender: "Sélectionnez la Voix de Genre",
      submitRequest: "Soumettre la Demande",
      myRequests: "Mes Demandes",
      previewDescription: "Ceci est un aperçu approximatif de l'apparence de votre avatar sur le fond sélectionné. L'avatar s'ajustera automatiquement à la taille et à la position optimales pour la meilleure présentation. Vous pouvez changer à la fois l'avatar et le fond à tout moment avant de soumettre votre demande.",
      submissionSuccess: "Demande d'avatar soumise avec succès, une fois votre avatar généré, vous recevrez une notification par e-mail. Si votre demande concerne une leçon dans les Assignations, elle sera automatiquement liée.",
      promptError: "Veuillez entrer un message; il ne peut pas être vide.",
      charactersRemaining: "caractères restants",
    },
    pt: {
      title: "Gerador de Avatares com Animação",
      chooseAvatar: "Escolha seu Avatar",
      selectAvatar: "Selecionar Avatar",
      chooseBackground: "Escolha seu Fundo",
      selectBackground: "Selecionar Fundo",
      videoPreview: "Pré-visualização",
      selectBackgroundPreview: "Selecione um fundo para pré-visualizar",
      finalAdjustments: "Ajustes finais para gerar seu avatar",
      enterPrompt: "Digite sua Mensagem Aqui",
      enterSpeech: "Digite o discurso",
      chooseLanguage: "Escolha o Idioma",
      selectLanguage: "Selecione o idioma para a voz",
      chooseGender: "Escolha a Voz de Gênero",
      selectGender: "Selecione a Voz de Gênero",
      submitRequest: "Enviar Solicitação",
      myRequests: "Minhas Solicitações",
      previewDescription: "Esta é uma pré-visualização aproximada de como seu avatar ficará no fundo selecionado. O avatar se ajustará automaticamente ao tamanho e posição ideais para a melhor apresentação. Você pode alterar tanto o avatar quanto o fundo a qualquer momento antes de enviar sua solicitação.",
      submissionSuccess: "Solicitação de avatar enviada com sucesso, uma vez que seu avatar seja gerado, você receberá uma notificação por e-mail. Caso sua solicitação seja para uma lição em Atribuições, ela será vinculada automaticamente.",
      promptError: "Por favor, insira uma mensagem; não pode estar vazio.",
      charactersRemaining: "caracteres restantes",
    },
    de: {
      title: "Avatar-Generator mit Animation",
      chooseAvatar: "Wählen Sie Ihren Avatar",
      selectAvatar: "Avatar auswählen",
      chooseBackground: "Wählen Sie Ihren Hintergrund",
      selectBackground: "Hintergrund auswählen",
      videoPreview: "Vorschau",
      selectBackgroundPreview: "Wählen Sie einen Hintergrund zur Vorschau",
      finalAdjustments: "Letzte Anpassungen zur Erstellung Ihres Avatars",
      enterPrompt: "Geben Sie hier Ihre Nachricht ein",
      enterSpeech: "Geben Sie die Rede ein",
      chooseLanguage: "Sprache wählen",
      selectLanguage: "Sprache für die Stimme auswählen",
      chooseGender: "Wählen Sie die Geschlechtsstimme",
      selectGender: "Geschlechtsstimme auswählen",
      submitRequest: "Anfrage senden",
      myRequests: "Meine Anfragen",
      previewDescription: "Dies ist eine grobe Vorschau darauf, wie Ihr Avatar auf dem ausgewählten Hintergrund aussehen wird. Der Avatar passt sich automatisch an die optimale Größe und Position für die beste Präsentation an. Sie können sowohl den Avatar als auch den Hintergrund jederzeit ändern, bevor Sie Ihre Bewerbung einreichen.",
      submissionSuccess: "Avatar-Anfrage erfolgreich eingereicht, sobald Ihr Avatar generiert wird, erhalten Sie eine E-Mail-Benachrichtigung. Falls Ihre Anfrage für eine Lektion in den Zuweisungen ist, wird sie automatisch verknüpft.",
      promptError: "Bitte geben Sie eine Nachricht ein; es darf nicht leer sein.",
      charactersRemaining: "verbleibende Zeichen",
    },
    hi: {
      title: "एनिमशन के साथ अवतार जनरेटर",
      chooseAvatar: "अपना अवतार चुनें",
      selectAvatar: "अवतार का चयन करें",
      chooseBackground: "अपना ष्ठभूमि चुनें",
      selectBackground: "पृष्ठभूमि का चयन करें",
      videoPreview: "पूर्वावलोकन",
      selectBackgroundPreview: "पृष्ठभूमि के लिए एक पृष्ठभूमि चुनें",
      finalAdjustments: "अपने अवतार को अंतिम समायोजन",
      enterPrompt: "यहां अपना संदेश र्ज करें",
      enterSpeech: "भाषण दर्ज करें",
      chooseLanguage: "भाषा चुनें",
      selectLanguage: "आवाज के लिए भाषा का चयन करें",
      chooseGender: "लिंग की वाज़ चुनें",
      selectGender: "लिंग की आवाज़ का चयन करें",
      submitRequest: "अनुरोध सबमिट करें",
      myRequests: "मेरे अनुरोध",
      previewDescription: "यहां आपके अवतार की चयनित पृष्ठभूमि पर कैसी दिखेगी, इसका एक मोटा पूर्वावलोकन है। अवतार स्वचालित रूप से स्वोत्तम प्रस्तुति के लिए ष्टतम आकार और स्थिति में समायोजित हो जाएगा। आप अपने आवेदन जमा करने से पुले किसी भी समय अवतार और पृष्ठभूमि दोनों को बदल के हैं।",
      submissionSuccess: "अवतार अनुरोध सफलतापूर्वक सबमिट किया गया, एक बार जब आपका अवतार जनरेट हो जाएगा, तो आपको ईमेल द्वारा एक सूचना प्राप्त होगी। यदि आपका अनुरोध असाइनमेंट् में एक पाठ के लिए है, तो यह स्वचालित रूप से लिंक हो जाएगा।",
      promptError: "कृपया एक संदेश दर्ज करें; यह खाली नहीं हो सकता।",
      charactersRemaining: "शेष वर्ण",
    },
  };

  const t = translations[language];

  const audioName = useRef<string>("");
  const combineImageName = useRef<string>("");
  const slectedLanguage = useRef<string>(propLanguage || "");

  const [avatarArray, setAvatarArray] = useState<any[]>([]);
  const [lessions, setLessions] = useState<any[]>([]);
  const [selectedLesson, setSelectedLesson] =
    useState<SelectedLessonType | null>(null);
  const [backgroundArray, setBackgroundArray] = useState<any[]>([]);
  const [imageSrc, setImageSrc] = useState<string>("");
  const [vedioSrc, setVedioSrc] = useState<string>("");
  const [audioSrc, setAudioSrc] = useState<string>("");
  const [showVedio, setShowVedio] = useState<boolean>(false);

  const [showAudio, setShowAudio] = useState<boolean>(false);
  const [showImg, setShowImage] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const avatarImage = useRef<string>("");
  const backgroundImage = useRef<string>("");
  const [avatarSelected, setAvatarSelected] = useState<boolean>(false);
  const [bgSelected, setBgSelected] = useState<boolean>(false);
  const [selectedBackground, setSelectedBackground] = useState<string>('');
  const [selectedAvatar, setSelectedAvatar] = useState<string>('');

  const [shouldReset, setShouldReset] = useState<boolean>(false);

  // Añadir nuevo estado para controlar si el formulario fue enviado
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  // Añadir un ref para guardar el último estado enviado
  const lastSubmittedState = useRef({
    avatar: '',
    background: '',
    prompt: '',
    language: '',
    gender: ''
  });

  const [showTable, setShowTable] = useState(false);

  var languageOption = [
    "English",
    "Spanish",
    "French",
    "German",
    "Japanese",
    "Korean",
    "Chinese",
    "Russian",
    "Turkish",
    "Hindi",
    "Portuguese",
  ];

  const sassToken =
    "sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-11-14T05:08:44Z&st=2024-11-13T21:08:44Z&spr=https&sig=mAhLnyOIKLS7Kf3qqy7llpE0c1R32l1CQeWc8ABMd0M%3D";

  var genderOption = ["Male", "Female"];
  // var avatarOption = [
  //   {
  //     Id: 1,
  //     image_name: "man.png",
  //     imgPath:
  //       "https://avatargansstg.blob.core.windows.net/avatar/man.png?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-11-14T05:08:44Z&st=2024-11-13T21:08:44Z&spr=https&sig=mAhLnyOIKLS7Kf3qqy7llpE0c1R32l1CQeWc8ABMd0M%3D",
  //   },
  //   { Id: 2, image_name: "Choose Another", imgPath: "" },
  // ];
  var avatarOption = [
    {
      Id: 1,
      image_name: "avatar1.png",
      imgPath:
        "https://avatargansstg.blob.core.windows.net/avatar/avatar1.png?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-11-14T05:08:44Z&st=2024-11-13T21:08:44Z&spr=https&sig=mAhLnyOIKLS7Kf3qqy7llpE0c1R32l1CQeWc8ABMd0M%3D",
    },
    {
      Id: 2,
      image_name: "avatar2.png",
      imgPath:
        "https://avatargansstg.blob.core.windows.net/avatar/avatar2.png?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-11-14T05:08:44Z&st=2024-11-13T21:08:44Z&spr=https&sig=mAhLnyOIKLS7Kf3qqy7llpE0c1R32l1CQeWc8ABMd0M%3D",
    },
    {
      Id: 3,
      image_name: "avatar3.png",
      imgPath:
        "https://avatargansstg.blob.core.windows.net/avatar/avatar3.png?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-11-14T05:08:44Z&st=2024-11-13T21:08:44Z&spr=https&sig=mAhLnyOIKLS7Kf3qqy7llpE0c1R32l1CQeWc8ABMd0M%3D",
    },
    {
      Id: 4,
      image_name: "avatar4.png",
      imgPath:
        "https://avatargansstg.blob.core.windows.net/avatar/avatar4.png?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-11-14T05:08:44Z&st=2024-11-13T21:08:44Z&spr=https&sig=mAhLnyOIKLS7Kf3qqy7llpE0c1R32l1CQeWc8ABMd0M%3D",
    },
    {
      Id: 5,
      image_name: "avatar5.png",
      imgPath:
        "https://avatargansstg.blob.core.windows.net/avatar/avatar5.png?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-11-14T05:08:44Z&st=2024-11-13T21:08:44Z&spr=https&sig=mAhLnyOIKLS7Kf3qqy7llpE0c1R32l1CQeWc8ABMd0M%3D",
    },
    {
      Id: 6,
      image_name: "avatar6.png",
      imgPath:
        "https://avatargansstg.blob.core.windows.net/avatar/avatar6.png?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-11-14T05:08:44Z&st=2024-11-13T21:08:44Z&spr=https&sig=mAhLnyOIKLS7Kf3qqy7llpE0c1R32l1CQeWc8ABMd0M%3D",
    },
    {
      Id: 7,
      image_name: "avatar7.png",
      imgPath:
        "https://avatargansstg.blob.core.windows.net/avatar/avatar7.png?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-11-14T05:08:44Z&st=2024-11-13T21:08:44Z&spr=https&sig=mAhLnyOIKLS7Kf3qqy7llpE0c1R32l1CQeWc8ABMd0M%3D",
    },
    {
      Id: 8,
      image_name: "avatar8.png",
      imgPath:
        "https://avatargansstg.blob.core.windows.net/avatar/avatar8.png?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-11-14T05:08:44Z&st=2024-11-13T21:08:44Z&spr=https&sig=mAhLnyOIKLS7Kf3qqy7llpE0c1R32l1CQeWc8ABMd0M%3D",
    },
    {
      Id: 9,
      image_name: "avatar9.png",
      imgPath:
        "https://avatargansstg.blob.core.windows.net/avatar/avatar9.png?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-11-14T05:08:44Z&st=2024-11-13T21:08:44Z&spr=https&sig=mAhLnyOIKLS7Kf3qqy7llpE0c1R32l1CQeWc8ABMd0M%3D",
    },
    {
      Id: 10,
      image_name: "avatar10.png",
      imgPath:
        "https://avatargansstg.blob.core.windows.net/avatar/avatar10.png?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-11-14T05:08:44Z&st=2024-11-13T21:08:44Z&spr=https&sig=mAhLnyOIKLS7Kf3qqy7llpE0c1R32l1CQeWc8ABMd0M%3D",
    },
    {
      Id: 11,
      image_name: "avatar11.png",
      imgPath:
        "https://avatargansstg.blob.core.windows.net/avatar/avatar11.png?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-11-14T05:08:44Z&st=2024-11-13T21:08:44Z&spr=https&sig=mAhLnyOIKLS7Kf3qqy7llpE0c1R32l1CQeWc8ABMd0M%3D",
    },
    {
      Id: 12,
      image_name: "avatar12.png",
      imgPath:
        "https://avatargansstg.blob.core.windows.net/avatar/avatar12.png?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-11-14T05:08:44Z&st=2024-11-13T21:08:44Z&spr=https&sig=mAhLnyOIKLS7Kf3qqy7llpE0c1R32l1CQeWc8ABMd0M%3D",
    },
    {
      Id: 13,
      image_name: "avatar13.png",
      imgPath:
        "https://avatargansstg.blob.core.windows.net/avatar/avatar13.png?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-11-14T05:08:44Z&st=2024-11-13T21:08:44Z&spr=https&sig=mAhLnyOIKLS7Kf3qqy7llpE0c1R32l1CQeWc8ABMd0M%3D",
    },
    {
      Id: 14,
      image_name: "avatar14.png",
      imgPath:
        "https://avatargansstg.blob.core.windows.net/avatar/avatar14.png?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-11-14T05:08:44Z&st=2024-11-13T21:08:44Z&spr=https&sig=mAhLnyOIKLS7Kf3qqy7llpE0c1R32l1CQeWc8ABMd0M%3D",
    },
    {
      Id: 15,
      image_name: "avatar15.png",
      imgPath:
        "https://avatargansstg.blob.core.windows.net/avatar/avatar15.png?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-11-14T05:08:44Z&st=2024-11-13T21:08:44Z&spr=https&sig=mAhLnyOIKLS7Kf3qqy7llpE0c1R32l1CQeWc8ABMd0M%3D",
    },
    {
      Id: 16,
      image_name: "avatar16.png",
      imgPath:
        "https://avatargansstg.blob.core.windows.net/avatar/avatar16.png?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-11-14T05:08:44Z&st=2024-11-13T21:08:44Z&spr=https&sig=mAhLnyOIKLS7Kf3qqy7llpE0c1R32l1CQeWc8ABMd0M%3D",
    },
    {
      Id: 17,
      image_name: "avatar17.png",
      imgPath:
        "https://avatargansstg.blob.core.windows.net/avatar/avatar17.png?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-11-14T05:08:44Z&st=2024-11-13T21:08:44Z&spr=https&sig=mAhLnyOIKLS7Kf3qqy7llpE0c1R32l1CQeWc8ABMd0M%3D",
    },
    {
      Id: 18,
      image_name: "avatar18.png",
      imgPath:
        "https://avatargansstg.blob.core.windows.net/avatar/avatar18.png?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-11-14T05:08:44Z&st=2024-11-13T21:08:44Z&spr=https&sig=mAhLnyOIKLS7Kf3qqy7llpE0c1R32l1CQeWc8ABMd0M%3D",
    },
    {
      Id: 19,
      image_name: "avatar19.png",
      imgPath:
        "https://avatargansstg.blob.core.windows.net/avatar/avatar19.png?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-11-14T05:08:44Z&st=2024-11-13T21:08:44Z&spr=https&sig=mAhLnyOIKLS7Kf3qqy7llpE0c1R32l1CQeWc8ABMd0M%3D",
    },
    {
      Id: 20,
      image_name: "avatar20.png",
      imgPath:
        "https://avatargansstg.blob.core.windows.net/avatar/avatar20.png?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-11-14T05:08:44Z&st=2024-11-13T21:08:44Z&spr=https&sig=mAhLnyOIKLS7Kf3qqy7llpE0c1R32l1CQeWc8ABMd0M%3D",
    },
    {
      Id: 21,
      image_name: "avatar21.png",
      imgPath:
        "https://avatargansstg.blob.core.windows.net/avatar/avatar21.png?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-11-14T05:08:44Z&st=2024-11-13T21:08:44Z&spr=https&sig=mAhLnyOIKLS7Kf3qqy7llpE0c1R32l1CQeWc8ABMd0M%3D",
    },
    {
      Id: 22,
      image_name: "avatar22.png",
      imgPath:
        "https://avatargansstg.blob.core.windows.net/avatar/avatar22.png?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-11-14T05:08:44Z&st=2024-11-13T21:08:44Z&spr=https&sig=mAhLnyOIKLS7Kf3qqy7llpE0c1R32l1CQeWc8ABMd0M%3D",
    },
  ];
  // var backGoundOption = [
  //   {
  //     Id: 1,
  //     image_name: "bg1.png",
  //     imgPath:
  //       "https://avatargansstg.blob.core.windows.net/background-images/bg1.png?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-11-14T05:08:44Z&st=2024-11-13T21:08:44Z&spr=https&sig=mAhLnyOIKLS7Kf3qqy7llpE0c1R32l1CQeWc8ABMd0M%3D",
  //   },
  //   { Id: 2, image_name: "Choose Another", imgPath: "" },
  // ];
  var backGoundOption = [
    {
      Id: 1,
      image_name: "bg1.png",
      imgPath:
        "https://avatargansstg.blob.core.windows.net/background-images/bg1.png?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-11-14T05:08:44Z&st=2024-11-13T21:08:44Z&spr=https&sig=mAhLnyOIKLS7Kf3qqy7llpE0c1R32l1CQeWc8ABMd0M%3D",
    },
    {
      Id: 2,
      image_name: "bg2.png",
      imgPath:
        "https://avatargansstg.blob.core.windows.net/background-images/bg2.png?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-11-14T05:08:44Z&st=2024-11-13T21:08:44Z&spr=https&sig=mAhLnyOIKLS7Kf3qqy7llpE0c1R32l1CQeWc8ABMd0M%3D",
    },
    {
      Id: 3,
      image_name: "bg3.png",
      imgPath:
        "https://avatargansstg.blob.core.windows.net/background-images/bg3.png?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-11-14T05:08:44Z&st=2024-11-13T21:08:44Z&spr=https&sig=mAhLnyOIKLS7Kf3qqy7llpE0c1R32l1CQeWc8ABMd0M%3D",
    },
    {
      Id: 4,
      image_name: "bg4.png",
      imgPath:
        "https://avatargansstg.blob.core.windows.net/background-images/bg4.png?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-11-14T05:08:44Z&st=2024-11-13T21:08:44Z&spr=https&sig=mAhLnyOIKLS7Kf3qqy7llpE0c1R32l1CQeWc8ABMd0M%3D",
    },
    {
      Id: 5,
      image_name: "bg5.png",
      imgPath:
        "https://avatargansstg.blob.core.windows.net/background-images/bg5.png?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-11-14T05:08:44Z&st=2024-11-13T21:08:44Z&spr=https&sig=mAhLnyOIKLS7Kf3qqy7llpE0c1R32l1CQeWc8ABMd0M%3D",
    },
    {
      Id: 6,
      image_name: "bg6.png",
      imgPath:
        "https://avatargansstg.blob.core.windows.net/background-images/bg6.png?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-11-14T05:08:44Z&st=2024-11-13T21:08:44Z&spr=https&sig=mAhLnyOIKLS7Kf3qqy7llpE0c1R32l1CQeWc8ABMd0M%3D",
    },
    {
      Id: 7,
      image_name: "bg7.png",
      imgPath:
        "https://avatargansstg.blob.core.windows.net/background-images/bg7.png?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-11-14T05:08:44Z&st=2024-11-13T21:08:44Z&spr=https&sig=mAhLnyOIKLS7Kf3qqy7llpE0c1R32l1CQeWc8ABMd0M%3D",
    },
    {
      Id: 8,
      image_name: "bg8.png",
      imgPath:
        "https://avatargansstg.blob.core.windows.net/background-images/bg8.png?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-11-14T05:08:44Z&st=2024-11-13T21:08:44Z&spr=https&sig=mAhLnyOIKLS7Kf3qqy7llpE0c1R32l1CQeWc8ABMd0M%3D",
    },
    {
      Id: 9,
      image_name: "bg9.png",
      imgPath:
        "https://avatargansstg.blob.core.windows.net/background-images/bg9.png?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-11-14T05:08:44Z&st=2024-11-13T21:08:44Z&spr=https&sig=mAhLnyOIKLS7Kf3qqy7llpE0c1R32l1CQeWc8ABMd0M%3D",
    },
    {
      Id: 10,
      image_name: "bg10.png",
      imgPath:
        "https://avatargansstg.blob.core.windows.net/background-images/bg10.png?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-11-14T05:08:44Z&st=2024-11-13T21:08:44Z&spr=https&sig=mAhLnyOIKLS7Kf3qqy7llpE0c1R32l1CQeWc8ABMd0M%3D",
    },
    {
      Id: 11,
      image_name: "bg11.png",
      imgPath:
        "https://avatargansstg.blob.core.windows.net/background-images/bg11.png?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-11-14T05:08:44Z&st=2024-11-13T21:08:44Z&spr=https&sig=mAhLnyOIKLS7Kf3qqy7llpE0c1R32l1CQeWc8ABMd0M%3D",
    },
    {
      Id: 12,
      image_name: "bg12.png",
      imgPath:
        "https://avatargansstg.blob.core.windows.net/background-images/bg12.png?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-11-14T05:08:44Z&st=2024-11-13T21:08:44Z&spr=https&sig=mAhLnyOIKLS7Kf3qqy7llpE0c1R32l1CQeWc8ABMd0M%3D",
    },
    {
      Id: 13,
      image_name: "bg13.png",
      imgPath:
        "https://avatargansstg.blob.core.windows.net/background-images/bg13.png?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-11-14T05:08:44Z&st=2024-11-13T21:08:44Z&spr=https&sig=mAhLnyOIKLS7Kf3qqy7llpE0c1R32l1CQeWc8ABMd0M%3D",
    },
    {
      Id: 14,
      image_name: "bg14.png",
      imgPath:
        "https://avatargansstg.blob.core.windows.net/background-images/bg14.png?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-11-14T05:08:44Z&st=2024-11-13T21:08:44Z&spr=https&sig=mAhLnyOIKLS7Kf3qqy7llpE0c1R32l1CQeWc8ABMd0M%3D",
    },
    {
      Id: 15,
      image_name: "bg15.png",
      imgPath:
        "https://avatargansstg.blob.core.windows.net/background-images/bg15.png?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-11-14T05:08:44Z&st=2024-11-13T21:08:44Z&spr=https&sig=mAhLnyOIKLS7Kf3qqy7llpE0c1R32l1CQeWc8ABMd0M%3D",
    },
    {
      Id: 16,
      image_name: "bg16.png",
      imgPath:
        "https://avatargansstg.blob.core.windows.net/background-images/bg16.png?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-11-14T05:08:44Z&st=2024-11-13T21:08:44Z&spr=https&sig=mAhLnyOIKLS7Kf3qqy7llpE0c1R32l1CQeWc8ABMd0M%3D",
    },
    {
      Id: 17,
      image_name: "bg17.png",
      imgPath:
        "https://avatargansstg.blob.core.windows.net/background-images/bg17.png?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-11-14T05:08:44Z&st=2024-11-13T21:08:44Z&spr=https&sig=mAhLnyOIKLS7Kf3qqy7llpE0c1R32l1CQeWc8ABMd0M%3D",
    },
    {
      Id: 18,
      image_name: "bg18.png",
      imgPath:
        "https://avatargansstg.blob.core.windows.net/background-images/bg18.png?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-11-14T05:08:44Z&st=2024-11-13T21:08:44Z&spr=https&sig=mAhLnyOIKLS7Kf3qqy7llpE0c1R32l1CQeWc8ABMd0M%3D",
    },
    {
      Id: 19,
      image_name: "bg19.png",
      imgPath:
        "https://avatargansstg.blob.core.windows.net/background-images/bg19.png?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-11-14T05:08:44Z&st=2024-11-13T21:08:44Z&spr=https&sig=mAhLnyOIKLS7Kf3qqy7llpE0c1R32l1CQeWc8ABMd0M%3D",
    },
    {
      Id: 20,
      image_name: "bg20.png",
      imgPath:
        "https://avatargansstg.blob.core.windows.net/background-images/bg20.png?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-11-14T05:08:44Z&st=2024-11-13T21:08:44Z&spr=https&sig=mAhLnyOIKLS7Kf3qqy7llpE0c1R32l1CQeWc8ABMd0M%3D",
    },
    {
      Id: 21,
      image_name: "bg21.png",
      imgPath:
        "https://avatargansstg.blob.core.windows.net/background-images/bg21.png?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-11-14T05:08:44Z&st=2024-11-13T21:08:44Z&spr=https&sig=mAhLnyOIKLS7Kf3qqy7llpE0c1R32l1CQeWc8ABMd0M%3D",
    },
    {
      Id: 22,
      image_name: "bg22.png",
      imgPath:
        "https://avatargansstg.blob.core.windows.net/background-images/bg22.png?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-11-14T05:08:44Z&st=2024-11-13T21:08:44Z&spr=https&sig=mAhLnyOIKLS7Kf3qqy7llpE0c1R32l1CQeWc8ABMd0M%3D",
    },
    {
      Id: 23,
      image_name: "bg23.png",
      imgPath:
        "https://avatargansstg.blob.core.windows.net/background-images/bg23.png?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-11-14T05:08:44Z&st=2024-11-13T21:08:44Z&spr=https&sig=mAhLnyOIKLS7Kf3qqy7llpE0c1R32l1CQeWc8ABMd0M%3D",
    },
    {
      Id: 24,
      image_name: "bg24.png",
      imgPath:
        "https://avatargansstg.blob.core.windows.net/background-images/bg24.png?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-11-14T05:08:44Z&st=2024-11-13T21:08:44Z&spr=https&sig=mAhLnyOIKLS7Kf3qqy7llpE0c1R32l1CQeWc8ABMd0M%3D",
    },
  ];

  // Modificar la función para recibir los valores actuales
  const handleAnyChange = (currentValues?: { 
    prompt: string; 
    language: string; 
    gender: string; 
  }) => {
    if (isSubmitted) {
      const currentFormState = {
        avatar: selectedAvatar,
        background: selectedBackground,
        prompt: currentValues?.prompt || '',
        language: currentValues?.language || '',
        gender: currentValues?.gender || ''
      };

      const hasChanges = 
        currentFormState.avatar !== lastSubmittedState.current.avatar ||
        currentFormState.background !== lastSubmittedState.current.background ||
        currentFormState.prompt !== lastSubmittedState.current.prompt ||
        currentFormState.language !== lastSubmittedState.current.language ||
        currentFormState.gender !== lastSubmittedState.current.gender;

      if (hasChanges) {
        setIsSubmitted(false);
      }
    }
  };

  const handleAvatarValue = (avatar: any) => {
    handleAnyChange(); // Detectar cambio en avatar
    if (typeof avatar == "string") {
      if (avatar.includes('blob.core.windows.net')) {
        // Guardamos la URL sin el token SAS
        const baseUrl = avatar.split('?')[0];
        avatarImage.current = baseUrl;
        const fileName = baseUrl.split('/').pop() || avatar;
        setSelectedAvatar(fileName);
        setAvatarSelected(true);
        localStorage.setItem('selectedAvatarUrl', baseUrl);
        localStorage.setItem('selectedAvatar', fileName);
      } else {
        const baseUrl = `https://avatargansstg.blob.core.windows.net/avatar/${avatar}`;
        avatarImage.current = baseUrl;
        setSelectedAvatar(avatar);
        setAvatarSelected(true);
        localStorage.setItem('selectedAvatarUrl', baseUrl);
        localStorage.setItem('selectedAvatar', avatar);
      }
    } else {
      const formData = new FormData();
      formData.append("avatar_images", avatar);

      api
        .post("imageavatar/", formData)
        .then((res) => {
          const fileName = res.data.avatar_name;
          const baseUrl = `https://avatargansstg.blob.core.windows.net/avatar/${fileName}`;
          avatarImage.current = baseUrl;
          setSelectedAvatar(fileName);
          setAvatarSelected(true);
          localStorage.setItem('selectedAvatarUrl', baseUrl);
          localStorage.setItem('selectedAvatar', fileName);
        })
        .catch((ex) => {
          console.log(ex);
          toast.error("Choose Another Avatar", {
            autoClose: 1500,
          });
        });
    }
  };
  const handleBackValue = (background: any) => {
    handleAnyChange(); // Detectar cambio en background
    if (typeof background == "string") {
      if (background.includes('blob.core.windows.net')) {
        // Guardamos la URL sin el token SAS
        const baseUrl = background.split('?')[0];
        backgroundImage.current = baseUrl;
        const fileName = baseUrl.split('/').pop() || background;
        setSelectedBackground(fileName);
        setBgSelected(true);
        localStorage.setItem('selectedBackgroundUrl', baseUrl);
        localStorage.setItem('selectedBackground', fileName);
      } else {
        const baseUrl = `https://avatargansstg.blob.core.windows.net/background-images/${background}`;
        backgroundImage.current = baseUrl;
        setSelectedBackground(background);
        setBgSelected(true);
        localStorage.setItem('selectedBackgroundUrl', baseUrl);
        localStorage.setItem('selectedBackground', background);
      }
    } else {
      const formData = new FormData();
      formData.append("background_image", background);

      api
        .post("bgimage/", formData)
        .then((res) => {
          const fileName = res.data.filename;
          const baseUrl = `https://avatargansstg.blob.core.windows.net/background-images/${fileName}`;
          backgroundImage.current = baseUrl;
          setSelectedBackground(fileName);
          setBgSelected(true);
          localStorage.setItem('selectedBackgroundUrl', baseUrl);
          localStorage.setItem('selectedBackground', fileName);
        })
        .catch((ex) => {
          console.log(ex);
          toast.error("Choose Another Background Image", {
            autoClose: 1500,
          });
        });
    }
  };

  const storeAvatarVideo = (videoUrl: string) => {
    let data: {
      lessonId?: string;
      lessonName?: string;
      videoUrl: string;
      userMail?: string;
    } = {
      videoUrl,
    };
    let baseUrl = "";
    if (database) {
      baseUrl =
        database === "prod"
          ? "https://api.acv.app"
          : "https://acv-app-api-preprod.azurewebsites.net";
    }
    if (selectedLesson) {
      data["lessonId"] = selectedLesson.lessonId;
      data["lessonName"] = selectedLesson.lessonName;
    }
    if (owner) {
      data["userMail"] = owner;
    }

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl}/api/avatar-videos?slug=${slug}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    };
    if (slug && database && baseUrl) {
      axios
        .request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const getAvatar = () => {
    api.get("/getimageavatar/").then((res) => {
      setAvatarArray(res.data);
      console.log(avatarArray);
    });
  };
  const getBackground = () => {
    api.get("getbgimage/").then((res) => {
      setBackgroundArray(res.data);

      console.log(backgroundArray);
    });
  };
  const getFinalImage = () => {
    if (avatarImage.current && backgroundImage.current) {
      const values = {
        avatar_image: avatarImage.current,
        background_image: backgroundImage.current,
      };
      // avatar_image background_image

      api
        .post("finalcombinedimage/", values)
        .then((res) => {
          setShowImage(true);
          setImageSrc(res.data.image_url);
          combineImageName.current = res.data.combined_image;
        })
        .catch((error) => {
          console.log(error);
          toast.error("Cannot Combined the Image", {
            autoClose: 1500,
          });
        });
    } else {
      setShowError(true);
    }
  };
  const vedioGeneration = () => {
    const filename = `${slug || "noslug"}_${owner || "noowner"}_${
      lessonID || "nolesson"
    }_${slectedLanguage.current || "english"}.wav`;

    const values = {
      combined_image_name: combineImageName.current,
      audio_file_name: audioName.current,
      filename_slug: slug || "noslug",
      filename_lang: slectedLanguage.current,
    };

    setOpenModal(true);
    setShowVedio(false);
    api
      .post("postonlyclick/", values)
      .then((res) => {
        setOpenModal(false);
        setShowVedio(true);
        if (!!res?.data?.final_result_video) {
          slug && storeAvatarVideo(res.data.final_result_video);
          setVedioSrc(res.data.final_result_video);
        } else {
          toast.error("Video is not generated!", {
            autoClose: 1500,
          });
        }
      })
      .catch((ex) => {
        console.log(ex);
        setOpenModal(false);
        toast.error("Video is not generated!", {
          autoClose: 1500,
        });
      });
  };
  const getAllLessons = () => {
    const accessToken = localStorage.getItem("accessToken");
    let baseUrl = "";
    if (database) {
      baseUrl =
        database === "prod"
          ? "https://api.acv.app"
          : "https://acvappapi-preprod.acv.app";
    }
    if (baseUrl && accessToken) {
      axios
        .post(
          `${baseUrl}/api/lession/get-lession-by-teacher?slug=${slug}`,
          {
            LessonDate: new Date(new Date().setHours(0, 0, 0, 0)).toISOString(),
          },
          {
            headers: { Authorization: "Bearer " + accessToken },
          }
        )
        .then((res) => {
          const lessions = res.data.result;
          const formattedLessions = lessions.map(
            (lesson: { Title: string; ClassName: string }) => {
              return { header: lesson.ClassName, value: lesson.Title };
            }
          );
          setLessions(formattedLessions);
          if (lessonID) {
            const findedLesson: { Title: string; ClassName: string } =
              lessions.find(
                (lesson: { Title: string }) => lesson.Title === lessonID
              );
            setSelectedLesson({
              lessonId: findedLesson.Title,
              lessonName: findedLesson.ClassName,
            });
          }
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    }
  };

  const initialVal = {
    prompt: "",
    language: propLanguage || "",
    gender: "",
  };

  const validationSchema = Yup.object().shape({
    prompt: Yup.string().required(t.promptError),
    language: Yup.string().required(t.selectLanguage),
  });

  useEffect(() => {
    if (localStorage.getItem("accessToken")) {
      getAllLessons();
    }
  }, [localStorage.getItem("accessToken")]);

  useEffect(() => {
    const savedAvatar = localStorage.getItem('selectedAvatar');
    const savedBackground = localStorage.getItem('selectedBackground');
    
    if (savedAvatar) {
      avatarImage.current = savedAvatar;
      setAvatarSelected(true);
    }
    
    if (savedBackground) {
      backgroundImage.current = savedBackground;
      setBgSelected(true);
    }
  }, []);

  const handleFormSubmit = async (values: any, { resetForm }: any) => {
    if (avatarImage.current && backgroundImage.current) {
      const payload = {
        solution: slug ? "ACV" : "AVATAR_GEN",
        tenant: slug || "",
        userEmail: owner || "",
        avatarImage: localStorage.getItem('selectedAvatarUrl') || avatarImage.current,
        backgroundImage: localStorage.getItem('selectedBackgroundUrl') || backgroundImage.current,
        userPrompt: values.prompt,
        selectedLanguage: values.language,
        selectedGender: values.gender,
        selectedVoice: "Default",
        lessonId: selectedLesson?.lessonId || "",
        lessonName: selectedLesson?.lessonName || "",
      };
      setOpenModal(true);

      try {
        const baseUrl = database === "prod"
          ? "https://api.acv.app"
          : "https://acvappapi-preprod.acv.app";

        const response = await axios.request({
          method: "post",
          maxBodyLength: Infinity,
          url: `${baseUrl}/api/avatar-request`,
          headers: {
            "Content-Type": "application/json",
          },
          data: JSON.stringify(payload),
        });

        setOpenModal(false);
        setIsSubmitted(true);
        
        // Guardar el estado actual como el último enviado
        lastSubmittedState.current = {
          avatar: selectedAvatar,
          background: selectedBackground,
          prompt: values.prompt,
          language: values.language,
          gender: values.gender
        };

        // Usa la traducción correcta para el mensaje de éxito
        toast.success(
          t.submissionSuccess,
          {
            autoClose: false,
            closeOnClick: false,
            position: "top-right",
            style: {
              width: "300px",
              whiteSpace: "pre-line",
            }
          }
        );

      } catch (error) {
        setOpenModal(false);
        toast.error("Avatar Request submission failed");
        console.log(error);
      }
    } else {
      setShowError(true);
    }
  };

  // Añadimos un efecto de limpieza cuando el componente se desmonte
  useEffect(() => {
    return () => {
      // Limpieza al desmontar el componente
      localStorage.removeItem('selectedAvatar');
      localStorage.removeItem('selectedBackground');
      localStorage.removeItem('selectedAvatarUrl');
      localStorage.removeItem('selectedBackgroundUrl');
    };
  }, []);

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const translateText = async (text: string, targetLang: string) => {
    try {
      const response = await axios.post(
        'https://api.cognitive.microsofttranslator.com/translate',
        [{ Text: text }],
        {
          params: {
            'api-version': '3.0',
            'to': targetLang
          },
          headers: {
            'Ocp-Apim-Subscription-Key': 'YOUR_AZURE_SUBSCRIPTION_KEY', // Reemplaza con tu clave de suscripción
            'Content-Type': 'application/json',
            'Ocp-Apim-Subscription-Region': 'YOUR_REGION' // Reemplaza con tu región
          }
        }
      );

      return response.data[0].translations[0].text;
    } catch (error) {
      console.error('Error translating text:', error);
      return text;
    }
  };

  const maxCharacters = 350; // Definir el límite de caracteres

  return (
    <div className={`composer-container ${isDarkMode ? 'dark' : 'light'}`}>
      <div className="composer-layout">
        <header className="composer-header">
          <TEXT headingValue={t.title} />
        </header>
        <div className="control-panel">
          <Button className="control-button" onClick={() => setIsSidebarOpen(true)}>
            <FaList />
          </Button>
          <Button className="control-button" onClick={toggleDarkMode}>
            <FaLightbulb />
          </Button>
          <select className="control-button" value={language} onChange={handleLanguageChange}>
            <option value="en">English</option>
            <option value="es">Español</option>
            <option value="fr">Français</option>
            <option value="pt">Português</option>
            <option value="de">Deutsch</option>
            <option value="hi">हिंदी</option>
          </select>
        </div>
      </div>
      <div className="composer-layout">
        <div className="column column-1">
          <div className="row row-1">
            <DROPDOWNWITHIMAGECONTAINER
              headingValue={t.chooseAvatar}
              handleAvatarValue={handleAvatarValue}
              optionValue={avatarOption}
              type="avatar"
            />
          </div>
          <div className="row row-2">
            {/* Imagen del avatar */}
          </div>
          <div className="row row-3">
            {/* Otro contenido si es necesario */}
          </div>
        </div>

        <div className="column column-2">
          <div className="row row-1">
            <DROPDOWNWITHIMAGECONTAINER
              headingValue={t.chooseBackground}
              handleAvatarValue={handleBackValue}
              optionValue={backGoundOption}
              type="background"
            />
          </div>
          <div className="row row-2">
            {/* Imagen del background */}
          </div>
          <div className="row row-3">
            {/* Otro contenido si es necesario */}
          </div>
        </div>

        <div className="column column-3">
          <div className="row row-1">
            <h2 className="video-preview-title" style={{ color: isDarkMode ? '#fff' : '#000' }}>
              
              {t.videoPreview}
            </h2>
          </div>
          <div className="row row-2">
            {selectedAvatar && selectedBackground ? (
              <BackgroundPreview 
                backgroundUrl={`https://avatargansstg.blob.core.windows.net/background-images/${selectedBackground}?${sassToken}`}
                avatarUrl={`https://avatargansstg.blob.core.windows.net/avatar/${selectedAvatar}?${sassToken}`}
                t={t}
                isDarkMode={isDarkMode}
              />
            ) : (
              <div style={{ color: isDarkMode ? '#fff' : '#000' }}>
                {!selectedAvatar ? t.selectAvatar : t.selectBackground}
              </div>
            )}
          </div>
          <div className="row row-3">
            <p>{t.previewDescription}</p>
          </div>
        </div>
        <div className="column column-4">
          <div style={{ 
            padding: '.4rem',
            backgroundColor: isDarkMode ? '#444' : '#f5f5f5',
            borderRadius: '8px',
            minHeight: '200px',
            width: '95%',
            margin: '0 auto'
          }}>
            
            <h2 className="final-adjustments-title" style={{ marginTop: '14px', color: isDarkMode ? '#fff' : '#000' }}>
              {t.finalAdjustments}
            </h2>
            <Formik
              initialValues={initialVal}
              validationSchema={validationSchema}
              onSubmit={handleFormSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    
                    <Textarea
                      resize="none"
                      style={{
                        background: "#e5e5e5",
                        width: "100%",
                        marginTop: "3%",
                      }}
                      placeholder={t.enterSpeech}
                      value={values["prompt"]}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        if (e.target.value.length <= maxCharacters) {
                          handleChange(e);
                          handleAnyChange(values);
                        }
                      }}
                      name="prompt"
                    />
                    <div style={{ fontSize: "12px", color: values["prompt"].length > maxCharacters - 10 ? 'red' : isDarkMode ? '#fff' : '#000' }}>
                      {`${values["prompt"].length}/${maxCharacters} ${t.charactersRemaining}`}
                    </div>
                    {errors.prompt && touched.prompt && (
                      <p style={{ color: "red", fontSize: "14px" }}>
                        {t.promptError}
                      </p>
                    )}
                  </div>

                  <COMBOBOX
                    headingValue={t.chooseLanguage}
                    SelectionValue={t.selectLanguage}
                    value={values["language"]}
                    optionValue={Object.values(languageOption)}
                    setValue={(field: string, value: string) => {
                      setFieldValue(field, value);
                      handleAnyChange(values);
                    }}
                    name={"language"}
                  />
                  {errors.language && touched.language && (
                    <p style={{ color: "red", fontSize: "14px" }}>
                      {errors.language}
                    </p>
                  )}
                  <COMBOBOX
                    headingValue={t.chooseGender}
                    SelectionValue={t.selectGender}
                    value={values["gender"]}
                    optionValue={genderOption}
                    setValue={(field: string, value: string) => {
                      setFieldValue(field, value);
                      handleAnyChange(values);
                    }}
                    name={"gender"}
                  />
                  <Button
                    className="submit-request-button"
                    style={{
                      marginTop: "7px",
                      background: isSubmitted ? "#cccccc" : "#585A96",
                      color: isSubmitted ? "#666666" : "White",
                      cursor: isSubmitted ? "not-allowed" : "pointer",
                      opacity: isSubmitted ? 0.7 : 1,
                      marginRight: "10px",
                    }}
                    type="submit"
                    disabled={isSubmitted}
                  >
                    {t.submitRequest}
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
          {openModal ? (
            <Modal
              open={openModal}
              onClose={() => {
                setOpenModal(false);
              }}
              closeOnOverlayClick={false}
              center
            >
              <Loader
                size="large"
                labelPosition="below"
                labelValue="Generating..."
              />
            </Modal>
          ) : null}

        </div>
      </div>
      <Sidebar 
        isOpen={isSidebarOpen} 
        onClose={() => setIsSidebarOpen(false)} 
        owner={owner} 
        language={language}
      />
    </div>
  );
};

export default AvatarGenerator2;
