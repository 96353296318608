import React, { useState, useEffect, useRef } from "react";
import "./Sidebar.css";
import RequestTable from "./RequestTable";
import axios from "axios";
import { useDarkMode } from '../context/DarkModeContext';

interface SidebarProps {
  isOpen: boolean;
  onClose: () => void;
  owner?: string;
  slug?: string;
  language: Language;
}

type Language = 'en' | 'es' | 'fr' | 'pt' | 'de' | 'hi';

export const sidebarTranslations = {
  en: {
    account: "Account",
    search: "Search...",
    date: "Date",
    avatar: "Avatar",
    background: "Background",
    prompt: "Prompt",
    language: "Language",
    gender: "Gender",
    class: "Class",
    status: "Status",
    video: "Video",
    actions: "Actions",
  },
  es: {
    account: "Cuenta",
    search: "Buscar...",
    date: "Fecha",
    avatar: "Avatar",
    background: "Fondo",
    prompt: "Prompt",
    language: "Idioma",
    gender: "Género",
    class: "Clase",
    status: "Estado",
    video: "Video",
    actions: "Acciones",
  },
  fr: {
    account: "Compte",
    search: "Chercher...",
    date: "Date",
    avatar: "Avatar",
    background: "Fond",
    prompt: "Prompt",
    language: "Langue",
    gender: "Genre",
    class: "Classe",
    status: "Statut",
    video: "Vidéo",
    actions: "Actions",
  },
  pt: {
    account: "Conta",
    search: "Pesquisar...",
    date: "Data",
    avatar: "Avatar",
    background: "Fundo",
    prompt: "Prompt",
    language: "Idioma",
    gender: "Gênero",
    class: "Classe",
    status: "Status",
    video: "Vídeo",
    actions: "Ações",
  },
  de: {
    account: "Konto",
    search: "Suchen...",
    date: "Datum",
    avatar: "Avatar",
    background: "Hintergrund",
    prompt: "Prompt",
    language: "Sprache",
    gender: "Geschlecht",
    class: "Klasse",
    status: "Status",
    video: "Video",
    actions: "Aktionen",
  },
  hi: {
    account: "खाता",
    search: "खोजें...",
    date: "तारीख",
    avatar: "अवतार",
    background: "पृष्ठभूमि",
    prompt: "प्रॉम्प्ट",
    language: "भाषा",
    gender: "लिंग",
    class: "कक्षा",
    status: "स्थिति",
    video: "वीडियो",
    actions: "क्रियाएँ",
  },
};

const Sidebar: React.FC<SidebarProps> = ({ isOpen, onClose, owner, slug, language }) => {
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const sidebarRef = useRef<HTMLDivElement>(null);
  const { isDarkMode } = useDarkMode();

  const t = sidebarTranslations[language];

  useEffect(() => {
    if (isOpen) {
      const fetchData = async () => {
        try {
          const url = owner
            ? `https://acvappapi-preprod.acv.app/api/avatar-request/all?email=${owner}`
            : "https://acvappapi-preprod.acv.app/api/avatar-request/all";

          const response = await axios.get(url);
          const requests = response.data.requests;

          const filteredData = owner
            ? requests
            : requests.filter((request: any) => request.solution === "AVATAR_GEN");

          setData(filteredData);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      fetchData();
    }
  }, [isOpen, owner]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const filteredData = data.filter((item: any) => {
    const searchLower = searchTerm.toLowerCase();
    return (
      (item.userPrompt || "").toLowerCase().includes(searchLower) ||
      (item.selectedLanguage || "").toLowerCase().includes(searchLower) ||
      (item.selectedGender || "").toLowerCase().includes(searchLower) ||
      (item.lessonName || "").toLowerCase().includes(searchLower) ||
      (item.requestTime || "").toLowerCase().includes(searchLower)
    );
  });

  return (
    <div 
      ref={sidebarRef} 
      className={`sidebar ${isOpen ? "open" : ""}`} 
      style={{
        backgroundColor: isDarkMode ? '#333' : '#fff',
        color: isDarkMode ? '#fff' : '#000'
      }}
    >
      <button 
        className="close-button" 
        onClick={onClose}
        style={{
          color: isDarkMode ? '#fff' : '#000',
          background: 'none',
          border: 'none',
          fontSize: '24px',
          cursor: 'pointer'
        }}
      >
        &times;
      </button>
      <div className="sidebar-header">
        <h2>AVATAR GENERATION FOR SCHOOLS</h2>
        <p>{t.account}: {owner}</p>
        
      </div>
      <input
        type="text"
        placeholder={t.search}
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className={isDarkMode ? 'dark-mode' : 'light-mode'}
        style={{ 
          marginTop: "10px", 
          marginBottom: "10px", 
          width: "30%", 
          padding: "8px",
          backgroundColor: isDarkMode ? '#555' : '#fff',
          color: isDarkMode ? '#fff' : '#000'
        }}
      />
      <RequestTable data={filteredData} language={language} />
    </div>
  );
};

export default Sidebar; 