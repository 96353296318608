import React, { useState, useEffect } from 'react';

interface EditModalProps {
  item: any;
  onClose: () => void;
  onSave: (updatedItem: any) => void;
}

const EditModal: React.FC<EditModalProps> = ({ item, onClose, onSave }) => {
  const [formData, setFormData] = useState({
    userPrompt: '',
    selectedLanguage: '',
    selectedGender: '',
    ...item,
  });

  useEffect(() => {
    setFormData({
      userPrompt: item.userPrompt || '',
      selectedLanguage: item.selectedLanguage || '',
      selectedGender: item.selectedGender || '',
    });
  }, [item]);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLSelectElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSave = () => {
    const updatedItem = {
      ...item,
      userPrompt: formData.userPrompt,
      selectedLanguage: languageMap[formData.selectedLanguage] || formData.selectedLanguage,
      selectedGender: formData.selectedGender,
    };

    onSave(updatedItem);
  };

  const languageMap: { [key: string]: string } = {
    en: "English",
    es: "Spanish",
    fr: "French",
    pt: "Portuguese",
    de: "German",
    hi: "Hindi",
  };

  const charactersRemaining = 350 - formData.userPrompt.length;
  const characterCountStyle = {
    color: charactersRemaining < 10 ? 'red' : 'black',
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <h2>Editar Registro</h2>
        <div className="form-group">
          <label>Prompt:</label>
          <textarea
            name="userPrompt"
            value={formData.userPrompt}
            onChange={handleChange}
            placeholder="Prompt"
            maxLength={350}
            rows={4}
            style={{ width: '100%' }}
          />
          <div style={characterCountStyle}>
            {charactersRemaining} / 350 caracteres restantes
          </div>
        </div>
        <div className="form-group">
          <label>Language:</label>
          <select
            name="selectedLanguage"
            value={formData.selectedLanguage}
            onChange={handleChange}
          >
            <option value="en">English</option>
            <option value="es">Spanish</option>
            <option value="fr">French</option>
            <option value="pt">Portuguese</option>
            <option value="de">German</option>
            <option value="hi">Hindi</option>
          </select>
        </div>
        <div className="form-group">
          <label>Gender:</label>
          <select
            name="selectedGender"
            value={formData.selectedGender}
            onChange={handleChange}
          >
            <option value="male">Male</option>
            <option value="female">Female</option>
          </select>
        </div>
        <div className="button-group">
          <button onClick={handleSave}>Guardar</button>
          <button onClick={onClose}>Cancelar</button>
        </div>
      </div>
    </div>
  );
};

export default EditModal; 