import React from "react";
import {
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogBody,
  Button,
  Image,
  DialogTitle,
  DialogContent,
} from "@fluentui/react-components";
import "./BackgroundDialog.css";
import { useDarkMode } from '../../context/DarkModeContext';

interface BackgroundDialogProps {
  backgrounds: { Id: number; image_name: string; imgPath: string }[];
  selectBgImage: (url: string) => void;
  selectedImage: string;
}

const BackgroundDialog = (props: BackgroundDialogProps) => {
  const { isDarkMode } = useDarkMode();

  return (
    <Dialog modalType="non-modal">
      <DialogTrigger>
        <Button 
          className="background-dialog-button"
          style={{
            backgroundColor: isDarkMode ? '#333' : '#fff',
            color: isDarkMode ? '#fff' : '#000',
          }}
        >
          Select Background
        </Button>
      </DialogTrigger>
      <DialogSurface 
        className="dialog-surface"
        style={{
          backgroundColor: isDarkMode ? '#333' : '#fff',
          color: isDarkMode ? '#fff' : '#000',
        }}
      >
        <DialogBody>
          <DialogTitle>Choose Background</DialogTitle>
          <DialogContent className="dialog-content">
            <div className="background-grid-container">
              {props.backgrounds.map((option) => (
                <div key={option.Id} className="background-item">
                  {option.image_name == "Choose Another" ? (
                    <DialogTrigger disableButtonEnhancement>
                      <div
                        style={{
                          width: 130,
                          height: 180,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          border: "1px solid #ccc",
                          backgroundColor: isDarkMode ? '#555' : '#fff',
                          color: isDarkMode ? '#fff' : '#000',
                        }}
                        onClick={() => {
                          props.selectBgImage(option.image_name);
                        }}
                      >
                        {option.image_name}
                      </div>
                    </DialogTrigger>
                  ) : (
                    <>
                      {" "}
                      <DialogTrigger disableButtonEnhancement>
                        <Image
                          src={option.imgPath}
                          alt={option.image_name}
                          className={`background-image ${
                            props.selectedImage == option.imgPath
                              ? "selected"
                              : ""
                          }`}
                          width={130}
                          height={180}
                          onClick={() => {
                            props.selectBgImage(option.image_name);
                          }}
                        />
                      </DialogTrigger>
                      <p style={{ margin: 0 }}>{option.image_name}</p>
                    </>
                  )}
                </div>
              ))}
            </div>
          </DialogContent>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

export default BackgroundDialog;
