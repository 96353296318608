import React from "react";
import {
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogBody,
  Button,
  Image,
  DialogTitle,
  DialogContent,
} from "@fluentui/react-components";
import "./AvatarDialog.css";
import { useDarkMode } from '../../context/DarkModeContext';

interface AvatarDialogProps {
  avatars: { Id: number; image_name: string; imgPath: string }[];
  selectAvatar: (url: string) => void;
  selectedImage: string;
}

const AvatarDialog = (props: AvatarDialogProps) => {
  const { isDarkMode } = useDarkMode();

  return (
    <Dialog modalType="non-modal">
      <DialogTrigger>
        <Button 
          className="avatar-dialog-button"
          style={{
            backgroundColor: isDarkMode ? '#333' : '#fff',
            color: isDarkMode ? '#fff' : '#000',
          }}
        >
          Select Avatar
        </Button>
      </DialogTrigger>
      <DialogSurface 
        className="dialog-surface"
        style={{
          backgroundColor: isDarkMode ? '#333' : '#fff',
          color: isDarkMode ? '#fff' : '#000',
        }}
      >
        <DialogBody>
          <DialogTitle>Choose Avatar</DialogTitle>
          <DialogContent className="dialog-content">
            <div className="avatar-grid-container">
              {props.avatars.map((option) => (
                <div key={option.Id} className="avatar-item">
                  <DialogTrigger disableButtonEnhancement>
                    <Image
                      src={option.imgPath}
                      alt={option.image_name}
                      className={`avatar-image ${
                        props.selectedImage == option.imgPath
                          ? "selected"
                          : ""
                      }`}
                      width={130}
                      height={180}
                      onClick={() => {
                        props.selectAvatar(option.image_name);
                      }}
                    />
                  </DialogTrigger>
                  <p style={{ margin: 0 }}>{option.image_name}</p>
                </div>
              ))}
            </div>
          </DialogContent>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

export default AvatarDialog;
