import * as React from "react";
import {
    makeStyles,
    shorthands,
    Spinner
} from "@fluentui/react-components";
import './style.css';
const useStyles = makeStyles({
    container: {
      ...shorthands.gap("16px"),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      fontSize:"1rem",
      justifyContent:"center",
     
  
    },
    
   
  });
  const stylesValue:React.CSSProperties = {
    position:"relative",
    zIndex: "2000",
    top:"50%",
    width:"100%",
    padding:".5rem"
    // boxShadow:"2px 2px 15px 0px"
   
};
interface propsLoader{
    labelValue: string
    size:any
    labelPosition:any
}

const Loader : React.FC<propsLoader> = ({size,labelPosition,labelValue}) => {
   
    const styles = useStyles();
  return (
    <div className={styles.container} style={{ padding: "40px"}}>
     
      <Spinner 
     
        // style={stylesValue}
            size={size} 
            label={labelValue} 
            labelPosition={labelPosition} 
        />
     
    </div>
  );
};

export default Loader;