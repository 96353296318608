import axios from "axios";
 
// export const baseURL="http://74.235.53.222:8080/"  ;
//export const baseURL="https://avatar.eastus.cloudapp.azure.com:8002"  ;
//export const baseURL="http://52.165.6.9:8080";
export const baseURL="https://gan.acv.app:8002";
export const api= axios.create({
        baseURL: baseURL, // Set a base URL for requests
        // timeout: 5000, // Set a default timeout for requests (in milliseconds)
        // headers: {
        //   'Authorization': 'Bearer YourAccessToken', // Set default headers
        //   'Content-Type': 'application/json',
        // },
      });

      

// export const getImagesAndBackgrounds =()=>{
//     axios.get(
//         "https://huzaifat7524.pythonanywhere.com/Avatarandimage/"
//     ).then( res =>{

//         return res;
//     }      
//     ).catch(error=>{console.log("error")}) 
// } 

export const getBackgroundPreviewUrl = (fileName: string) => {
  return `${api.defaults.baseURL}/getBackgroundPreview/${fileName}`;
};

export const getBackgroundImage = async (fileName: string): Promise<string> => {
  try {
    const response = await api.get(`/getBackgroundImage/${fileName}`, {
      responseType: 'blob'
    });
    return URL.createObjectURL(response.data);
  } catch (error) {
    console.error('Error fetching background image:', error);
    throw error;
  }
};



