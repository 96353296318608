import React from "react";
import {
  FluentProvider,
  Toast,
  webLightTheme,
} from "@fluentui/react-components";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import Sidebar from './Component/Sidebar';
import AvatarGenerator2 from "./Pages/FormWithComponent";

type Language = 'en' | 'es' | 'fr' | 'pt' | 'de' | 'hi';

const App: React.FC = () => {
  const [isSidebarOpen, setSidebarOpen] = React.useState(false);

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const owner = params.get("owner") || "";
  const slug = params.get("slug") || "";
  const idiomaParam = params.get("language"); 
  const idioma: Language = 
    idiomaParam === 'en' || 
    idiomaParam === 'es' || 
    idiomaParam === 'fr' || 
    idiomaParam === 'pt' || 
    idiomaParam === 'de' || 
    idiomaParam === 'hi' 
      ? idiomaParam 
      : 'en';

  return (
    <FluentProvider theme={webLightTheme}>
      <div style={{ margin: "auto", padding: "10px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <AvatarGenerator2
            gpu={params.get("gpu") || ""}
            host={params.get("host") || ""}
            language={idioma}
            owner={owner}
            slug={slug}
            lessonID={params.get("lessonID") || ""}
            database={params.get("database") || ""}
          />
        </div>
      </div>
      <ToastContainer autoClose={2000} />
      <Sidebar
        isOpen={isSidebarOpen}
        onClose={() => setSidebarOpen(false)}
        owner={owner}
        slug={slug}
        language={idioma}
      />
    </FluentProvider>
  );
};

export default App;
