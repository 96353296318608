import * as React from "react";
import {
  Combobox,
  makeStyles,
  Option,
  shorthands,
  useId,
  Body1Strong,
  type ComboboxProps as FluentComboboxProps
} from "@fluentui/react-components";

const useStyles = makeStyles({
  root: {
    display: "grid",
    gridTemplateRows: "repeat(1fr)",
    justifyItems: "start",
    ...shorthands.gap("2px"),
    width: "100%",
  },
});

interface CustomComboboxProps {
  headingValue: string;
  SelectionValue: string;
  value: string;
  optionValue: string[];
  setValue: (field: string, value: string) => void;
  name: string;
}

const COMBOBOX: React.FC<CustomComboboxProps> = ({
  headingValue,
  SelectionValue,
  value,
  optionValue,
  setValue,
  name
}) => {
  const comboId = useId("combo-default");
  const styles = useStyles();
  
  const onSelect = (
    event: React.SyntheticEvent, 
    data: { optionText?: string; optionValue?: string }
  ) => {
    setValue(name, data?.optionText || '');
  };

  return (
    <div className={styles.root}>
      <Body1Strong 
        id={comboId} 
        style={{ 
          marginTop: "2%", 
          marginBottom: "2%", 
          fontSize: "1.5rem" 
        }}
      >
        {headingValue}
      </Body1Strong>
      <Combobox
        style={{ background: "#e5e5e5", width: "72.5%" }}
        aria-labelledby={comboId}
        placeholder={SelectionValue}
        onOptionSelect={onSelect}
        id={name}
        value={value || ''} 
      >
        {optionValue.map((option) => (
          <Option key={option}>
            {option}
          </Option>
        ))}
      </Combobox>
    </div>
  );
};

export default COMBOBOX;
